import Melody from './Melody'
import Instrument from './Instrument'

/** @constant {string} AWS_BUCKET - URL of the S3 bucket with telharmonium sound assets */
const AWS_BUCKET = 'https://telharmonium-web.s3.us-east-2.amazonaws.com'

class OnOff extends Instrument {
  getTrameId (trameId = 1) {
    return `TRAME${trameId}`
  }

  getModId (modId) {
    return modId > 0 ? `mod${modId}` : 'base'
  }

  getSoundId (trameId = 1, modId = 0) {
    return this.getTrameId(trameId) + this.getModId(modId)
  }

  play (trameId, modId) {
    this.sounds[this.getTrameId(trameId)][this.getModId(modId)].play()
  }

  stop (trameId, modId) {
    this.sounds[this.getTrameId(trameId)][this.getModId(modId)].stop()
  }
}

/** Describes the on/off instrument */
const onOff = new OnOff({
  variations: {
    base: {
      title: 'Normal',
      color: '#B2B2B2'
    },
    mod1: {
      title: 'Mod 1',
      color: '#F60000'
    },
    mod2: {
      title: 'Mod 2',
      color: '#00A244'
    },
    mod3: {
      title: 'Mod 3',
      color: '#FFD313'
    }
  },

  defaultVariation: 'base',

  TRAME1: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 1',
      color: '#B2B2B2',
      trackId: 'TRAME1'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 1',
      color: '#F60000',
      trackId: 'TRAME1mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 1',
      color: '#00A244',
      trackId: 'TRAME1mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 1',
      color: '#FFD313',
      trackId: 'TRAME1mod3'
    })
  },
  TRAME2: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 2',
      color: '#B2B2B2',
      trackId: 'TRAME2'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 2',
      color: '#F60000',
      trackId: 'TRAME2mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 2',
      color: '#00A244',
      trackId: 'TRAME2mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 2',
      color: '#FFD313',
      trackId: 'TRAME2mod3'
    })
  },
  TRAME3: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 3',
      color: '#B2B2B2',
      trackId: 'TRAME3'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 3',
      color: '#F60000',
      trackId: 'TRAME3mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 3',
      color: '#00A244',
      trackId: 'TRAME3mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 3',
      color: '#FFD313',
      trackId: 'TRAME3mod3'
    })
  },
  TRAME4: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 4',
      color: '#B2B2B2',
      trackId: 'TRAME4'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 4',
      color: '#F60000',
      trackId: 'TRAME4mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 4',
      color: '#00A244',
      trackId: 'TRAME4mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 4',
      color: '#FFD313',
      trackId: 'TRAME4mod3'
    })
  },
  TRAME5: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 5',
      color: '#B2B2B2',
      trackId: 'TRAME5'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 5',
      color: '#F60000',
      trackId: 'TRAME5mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 5',
      color: '#00A244',
      trackId: 'TRAME5mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 5',
      color: '#FFD313',
      trackId: 'TRAME5mod3'
    })
  },
  TRAME6: {
    base: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 6',
      color: '#B2B2B2',
      trackId: 'TRAME6'
    }),
    mod1: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 6',
      color: '#F60000',
      trackId: 'TRAME6mod1'
    }),
    mod2: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 6',
      color: '#00A244',
      trackId: 'TRAME6mod2'
    }),
    mod3: new Melody({
      baseUrl: AWS_BUCKET,
      title: 'Interrupteur 6',
      color: '#FFD313',
      trackId: 'TRAME6mod3'
    })
  }
})

export default onOff
