import Sound from './Sound'

/**
 * @class
 * @extends Sound
 * @classdesc Describes a drum sound
 */
class Drum extends Sound {
  intervalIds = []

  /**
   * Instanciates a new Drum
   * @constructs Drum
   * @param {string} baseUrl - URL used to fetch the assets
   * @param {string} wavId - Id of the sound asset
   * @param {number} minRate - Minimum rate for the sound
   * @param {number} maxRate - Maximum rate of the sound
   */
  constructor (description) {
    super(description, { loop: false })
  }

  /**
   * Changes the rate of the drum
   * @type {number}
   */
  set rate (newRate) {
    if (this.isPlaying) {
      this.stop()
      this.play(newRate)
    }
  }

  /**
   * Checks if the drum is playing
   * @type {boolean}
   */
  get isPlaying () {
    return this.intervalIds.length > 0
  }

  /**
   * Plays the drum
   * @param {number} [rate=200] - Defines the rate when it starts playing
   */
  play (rate = 200) {
    super.play()
    this.intervalIds.push(setInterval(() => super.play(), rate))
  }

  /** Stops the drum */
  stop () {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId))
    this.intervalIds = []
  }

  /**
   * Toggles the drum statement (started/stoped)
   * @param {boolean} [toggle=true] - Toggle for the howl
   * @param {number} [rate=1] - Defines the rate when it starts playing
   */
  toggle (toggle = true, rate = 1) {
    toggle ? this.play(rate) : setTimeout(() => this.stop(), rate)
  }
}

export default Drum
