import { Howl } from 'howler'

/**
 * @constant {object} HOWL_OPTIONS - Default option for the howl creation
 * It contains a set of fallbacks with multi-browser support:
 * @see [Firefox issue with html5 and preload options]{@link https://github.com/goldfire/howler.js/issues/1495}
 * @default
 */
const HOWL_OPTIONS = {
  preload: true,
  loop: true,
  volume: 0.8
}

/**
 * @class
 * @classdesc Describes a sound
 * @todo Should be a factory that populates some .env configs (aws buckets, other sources...)
 */
class Sound {
  description = {}

  get sound () {
    return this.howl
  }

  /**
   * Instanciates a new Sound
   * @constructs Sound
   * @param {string} baseUrl - URL used to fetch the assets
   * @param {string} wavId - Id of the wav assets (should be relatives to its S3 URL)
   * @param {object} extraOptions - Extra object for the howl object (will override HOWL_OPTIONS)
   */
  constructor (description = {}, extraOptions = {}) {
    const options = { ...HOWL_OPTIONS, ...extraOptions }
    const { baseUrl, trackId, ...props } = description

    Object.entries(props).forEach(([key, value]) => { this[key] = value })

    this.trackId = trackId

    this.howl = new Howl({
      ...options,
      src: `${baseUrl}/${trackId}.wav`,
      onplay: () => this.handlePlay(),
      onplayerror: () => this.handlePlayError(),
      onload: () => this.handleLoad(),
      onloaderror: () => this.handleLoadError(),
      onstop: () => this.handleStop(),
      onrate: () => this.handleRateChange()
    })
  }

  /**
   * Handles 'onplay' event
   * @see [`onplay` handler]{@link https://github.com/goldfire/howler.js#onplay-function}
   **/
  handlePlay () {
    console.debug(`sound ${this.trackId} is played`)
  }

  /**
   * Handles 'onplayerror' event
   * @see [`onplayerror` handler]{@link https://github.com/goldfire/howler.js#onplayerror-function}
   **/
  handlePlayError () {
    console.error(`sound ${this.trackId} failed to be played`)
  }

  /**
   * Handles 'onload' event
   * @see [`onload` handler]{@link https://github.com/goldfire/howler.js#onload-function}
   **/
  handleLoad () {
    console.debug(`sound ${this.trackId} is loading`)
  }

  /**
   * Handles 'onloaderror' event
   * @see [`onloaderror` handler]{@link https://github.com/goldfire/howler.js#onloaderror-function}
   **/
  handleLoadError () {
    console.error(`sound ${this.trackId} failed to be loaded`)
  }

  /**
   * Handles 'onstop' event
   * @see [`onstop` handler]{@link https://github.com/goldfire/howler.js#onstop-function}
   **/
  handleStop () {
    console.debug(`sound ${this.trackId} is stopped`)
  }

  /**
   * Handles 'onrate' event
   * @see [`onrate` handler]{@link https://github.com/goldfire/howler.js#onrate-function}
   **/
  handleRateChange () {
    console.debug(`rate of sound ${this.trackId} is changed`)
  }

  /**
   * Tests the sound
   * @param {number} [timeout=200] - Test the sound until timeout
   **/
  test (rate, timeout = 500) {
    console.debug(`sound ${this.trackId} is tested`)
    console.debug(`sound ${this.trackId} is ${this.howl.state()}`)

    this.play(rate)
    setTimeout(() => this.stop(), timeout)
  }

  /** Plays the sound **/
  play () {
    return this.howl.play()
  }

  /** Stops the sound **/
  stop () {
    this.howl.stop()
  }

  /**
   * Transforms this object into JSON
   * @returns {object} The JSON representation of the Sound
   */
  toJSON () {
    return {
      trackId: this.trackId,
      sound: this,
      ...this.description
    }
  }
}

export default Sound
