<template>
  <div class="TriggerButton"
       v-on:click="handleClick"
       v-bind:style="{ backgroundColor: backgroundColor,
                       color: triggerColor }">
    <span v-if="!isPlaying">▶</span>
    <span v-else>◼</span>
  </div>
</template>

<script>
export default {
  name: 'trigger-button',
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    playingColor: {
      type: String,
      default: '#fff'
    },
    stoppedColor: {
      type: String,
      default: '#fff'
    },
    backgroundColor: {
      type: String,
      default: '#B2B2B2'
    }
  },
  computed: {
    triggerColor: function () {
      let triggerColor = this.stoppedColor

      if (this.isPlaying) {
        triggerColor = this.playingColor
      }

      return triggerColor
    }
  },
  methods: {
    handleClick: function (event) {
      if (!this.isPlaying) {
        this.$emit('play')
      } else {
        this.$emit('stop')
      }
    }
  }
}
</script>

<style scoped>
/* DESKTOP SIZE */
@media only screen and (min-width: 1800px){
.TriggerButton {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  cursor: pointer;
  width: 150px;
  height: 85px;

  font-size: 32px;

  user-select: none;
}
}

/* LAPTOP SIZE */
@media only screen and (min-width: 1080px) and (max-width: 1800px) {
.TriggerButton {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 30px;
  margin-left: 25px;
  user-select: none;
}
}
</style>
