import Sound from './Sound'

/**
 * @class
 * @extends Sound
 * @classdesc Describes a melody sound
 */
class Melody extends Sound {
  playId = null

  /**
   * Changes the rate of the melody
   * @type {number}
   */
  set rate (newRate) {
    this.howl.rate(newRate)
  }

  /**
   * Checks if the melody is playing
   * @type {boolean}
   */
  get isPlaying () {
    return this.playId !== null
  }

  /**
   * Plays the melody
   * @param {number} [rate=1] - Defines the rate when it starts playing
   */
  play (rate = 1) {
    if (rate) {
      this.howl.rate(rate)
    }

    this.howl.fade(0.0, 0.8, 100)
    this.playId = super.play()
  }

  /** Stops the melody */
  stop () {
    this.howl.fade(0.8, 0.0, 100)
    super.stop()
    this.playId = null
  }

  /** Pauses the melody */
  pause () {
    this.howl.fade(0.8, 0.0, 100)
    super.pause()
    this.playId = null
  }

  /**
   * Toggles the melody statement (started/stoped)
   * @param {boolean} [toggle=true] - Toggle for the howl
   * @param {number} [rate=1] - Defines the rate when it starts playing
   */
  toggle (toggle = true, rate = 1) {
    toggle ? this.play(rate) : this.stop()
  }
}

export default Melody
