<template>
  <div class="DurationInput">
    <div class="InputButton"
         v-if="!isDisabled"
         v-on:click="handleDecreaseclick">
      -
    </div>
    <input type="number"
           ref="inputNumber"
           v-bind:disabled="isDisabled"
           v-bind:step="durationStep"
           v-bind:min="minDuration"
           v-bind:value="cellDuration"
           v-bind:max="maxDuration"
           v-on:change="handleInputChange"
           v-on:input="handleInputChange" />
    <div class="InputButton"
         v-if="!isDisabled"
         v-on:click="handleIncreaseClick">
      +
    </div>
  </div>
</template>

<script>
export default {
  name: 'duration-input',
  props: {
    cellDuration: {
      type: Number,
      default: 0.5
    },
    minDuration: {
      type: Number,
      default: 0
    },
    maxDuration: {
      type: Number,
      default: 1
    },
    durationStep: {
      type: Number,
      default: 0.1
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      durationSlice: 1
    }
  },
  computed: {
    stepDecimals: function () {
      const step = this.durationStep.toString()
      return step.includes('.') ? step.split('.')[1].length : 0
    }
  },
  methods: {
    setDurationValue: function (value) {
      if (value <= this.maxDuration && value >= this.minDuration) {
        this.$refs.inputNumber.value = value.toFixed(this.stepDecimals)
        this.$refs.inputNumber.dispatchEvent(new Event('input'))
      }
    },
    getDurationValue: function () {
      return Number.parseFloat(this.$refs.inputNumber.value)
    },
    handleIncreaseClick: function () {
      this.setDurationValue(this.getDurationValue() + this.durationStep)
    },
    handleDecreaseClick: function () {
      this.setDurationValue(this.getDurationValue() - this.durationStep)
    },
    handleInputChange: function () {
      const currentDuration = this.getDurationValue()
      this.$emit('changeDuration', currentDuration)
    }
  }
}
</script>

<style scoped>
  .DurationInput {
    position: relative;
    height: 40px;
    width: 120px;
    left: 320px;
    display: flex;
    flex-direction: row;
  }

  .InputButton {
    cursor: pointer;
    user-select: none;
  }

  input, .InputButton {
    font-size: 20px;
    color: #666666;
    background-color: #fff;

    margin: 0;
    padding: 0;

    border-width: 2px;
    border-style: solid;
  }

  .InputButton:hover {
    color: #fff;
    background-color: #666666;
  }

  .InputButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100px;
    height: 100%;
  }

  input {
    height: 100%;
    width: 100%;

    text-align: center;
  }

  input:disabled {
    background-color: #666666;
    color: #fff;
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
