import Sound from './Sound'
import Instrument from './Instrument'

/** @constant {string} AWS_BUCKET - URL of the S3 bucket with telharmonium sound assets */
const AWS_BUCKET = 'https://telharmonium-web.s3.us-east-2.amazonaws.com'

class Click extends Sound {
  constructor (description) {
    super(description, { loop: false })
  }

  click () {
    this.test(100)
  }
}

function generateMappingIds (trameIds, modIds) {
  const mapping = []

  for (const trameId of trameIds) {
    for (const modId of modIds) {
      mapping.push(trameId + (modId === 'base' ? '' : modId))
    }
  }

  return mapping
}

/** Describes the telharmonium instrument */
const clicks = new Instrument({
  CLICK_1_ON: new Click({
    baseUrl: AWS_BUCKET,
    trackId: 'Click1-on',
    mappingIds: generateMappingIds(
      ['TRAME2', 'TRAME4', 'TRAME6'],
      ['base', 'mod1', 'mod2', 'mod3']
    )
  }),
  CLICK_1_OFF: new Click({
    baseUrl: AWS_BUCKET,
    trackId: 'Click1-off',
    mappingIds: generateMappingIds(
      ['TRAME2', 'TRAME4', 'TRAME6'],
      ['base', 'mod1', 'mod2', 'mod3']
    )
  }),

  CLICK_2_ON: new Click({
    baseUrl: AWS_BUCKET,
    trackId: 'Click2-on',
    mappingIds: generateMappingIds(
      ['TRAME1', 'TRAME3', 'TRAME5'],
      ['base', 'mod1', 'mod2', 'mod3']
    )
  }),
  CLICK_2_OFF: new Click({
    baseUrl: AWS_BUCKET,
    trackId: 'Click2-off',
    mappingIds: generateMappingIds(
      ['TRAME1', 'TRAME3', 'TRAME5'],
      ['base', 'mod1', 'mod2', 'mod3']
    )
  })
})

export default clicks
