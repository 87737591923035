/**
 * @class
 * @classdesc Describes an instrument
 */
class Instrument {
  variations = {}
  defaultVariation = ''
  sounds = {}

  constructor (definition) {
    const { variations = {}, defaultVariation = '', ...sounds } = definition

    this.variations = variations
    this.defaultVariation = defaultVariation
    this.sounds = sounds
  }

  /**
   * Get all the howls as an array
   * @type {Sound[]}
   */
  get howls () {
    return Array.from(Object.values(this.sounds))
  }

  hasVariations () {
    return Object.keys(this.variations).length > 0
  }

  getSoundId () {
    console.log('not implemented')
  }

  getSoundVariations (variationId) {
    const sounds = {}

    for (const lib of Object.values(this.sounds)) {
      const sound = lib[variationId]

      if (sound) {
        sounds[sound.trackId] = sound
      }
    }

    return sounds
  }

  test (soundId, rate) {
    this.sounds[soundId].test(rate)
  }

  play (soundId, rate) {
    this.sounds[soundId].play(rate)
  }

  stop (soundId, rate) {
    this.sounds[soundId].stop(rate)
  }
}

export default Instrument
