<template>
<div class="VariationSelector"
     v-on:click="handleClick"
     v-bind:style="{ backgroundColor: unselectedColor,
                     color: titleColor }">
  <div class="Title"
       v-bind:style="{ backgroundColor: titleBackground }">
    {{ title }}
  </div>
</div>
</template>

<script>
export default {
  name: 'variation-selector',
  props: {
    variationId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Variation'
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    selectedColor: {
      type: String,
      default: '#666666'
    },
    color: {
      type: String,
      default: '#fff'
    },
    unselectedColor: {
      type: String,
      default: '#CCCCCC'
    }
  },
  computed: {
    titleBackground: function () {
      return this.isSelected ? this.selectedColor : this.unselectedColor
    },
    titleColor: function () {
      return this.isSelected ? this.unselectedColor : this.selectedColor
    }
  },
  methods: {
    handleClick: function (event) {
      this.$emit('selectVariation', this.variationId)
    }
  }
}
</script>

<style scoped>
.VariationSelector {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  cursor: pointer;
  height: 80px;

  font-size: 20px;

}

.Title {
  margin: 8px;
  padding: 12px;
  user-select: none;
}
</style>
