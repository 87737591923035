import Drum from './Drum'
import Melody from './Melody'
import Instrument from './Instrument'

/** @constant {string} AWS_BUCKET - URL of the S3 bucket with telharmonium sound assets */
const AWS_BUCKET = 'https://telharmonium-web.s3.us-east-2.amazonaws.com'

const MELODY_RATE_MIN = 0.5
const MELODY_RATE_MAX = 2
const BASS_RATE_MIN = 0.5
const BASS_RATE_MAX = 1.5
const DRUM_RATE_MIN = 100
const DRUM_RATE_MAX = 1000

/** Describes the telharmonium instrument */
const telharmonium = new Instrument({
  melody1Sound: new Melody({
    baseUrl: AWS_BUCKET,
    minRate: MELODY_RATE_MIN,
    maxRate: MELODY_RATE_MAX,
    initialRate: 1,
    trackId: 'melody1Sound',
    title: 'Mélodie 1',
    color: '#C25146'
  }),
  melody2Sound: new Melody({
    baseUrl: AWS_BUCKET,
    minRate: MELODY_RATE_MIN,
    maxRate: MELODY_RATE_MAX,
    initialRate: 1,
    title: 'Mélodie 2',
    color: '#CF7E34',
    trackId: 'melody2Sound'
  }),
  melody3Sound: new Melody({
    baseUrl: AWS_BUCKET,
    minRate: MELODY_RATE_MIN,
    maxRate: MELODY_RATE_MAX,
    initialRate: 1,
    title: 'Mélodie 3',
    color: '#D9A83E',
    trackId: 'melody3Sound'
  }),

  motorBassSound: new Melody({
    baseUrl: AWS_BUCKET,
    minRate: BASS_RATE_MIN,
    maxRate: BASS_RATE_MAX,
    initialRate: 1,
    title: 'Basse',
    color: '#A3BA50',
    trackId: 'motorBassSound'
  }),

  drumImpactLittle: new Drum({
    baseUrl: AWS_BUCKET,
    minRate: DRUM_RATE_MIN,
    maxRate: DRUM_RATE_MAX,
    initialRate: 200,
    title: 'Petit drum',
    color: '#485A6D',
    trackId: 'drumImpactLittle'
  }),
  drumImpactMedium: new Drum({
    baseUrl: AWS_BUCKET,
    minRate: DRUM_RATE_MIN,
    maxRate: DRUM_RATE_MAX,
    initialRate: 200,
    title: 'Moyen drum',
    color: '#709EB2',
    trackId: 'drumImpactMedium'
  }),
  drumImpactBiggest: new Drum({
    baseUrl: AWS_BUCKET,
    minRate: DRUM_RATE_MIN,
    maxRate: DRUM_RATE_MAX,
    initialRate: 200,
    title: 'Grand drum',
    color: '#B9D1D5',
    trackId: 'drumImpactBiggest'
  })
})

export default telharmonium
