<template>
<div v-bind:class="[ 'Sequencer', { PlayingSequencer: isPlaying }]"
     v-bind:style="{ backgroundColor: backgroundColor }">
  <div class="Controller">
    <div class="MainControls">
      <trigger-button v-bind:isPlaying="isPlaying"
                      v-on:play="handlePlay"
                      v-on:test="handleTest"
                      v-on:stop="handleStop" />
      <duration-input v-on:changeDuration="handleDurationChange"
                      v-bind:cellDuration="sliceDuration"
                      v-bind:isDisabled="isPlaying" />
    </div>
    <div class="Variations">
      <variation-selector v-for="(item, key) in instrument.variations"
                          v-bind:key="key"
                          v-bind:title="item.title"
                          v-bind:selectedColor="item.color"
                          v-bind:isSelected="selectedVariation === key"
                          v-on:selectVariation="handleSelectVariation"
                          v-bind:variationId="key" />
    </div>
  </div>
  <reader v-bind:isActive="isPlaying"
          v-bind:duration="sequenceDuration">
    <div class="Grid">
      <row v-for="(item, key) in selectedSounds"
           v-bind:key="key"
           v-bind="item">
        <cell v-for="(sequence, pos) in sequenceGrid"
              v-bind:soundId="key"
              v-bind:sequencePos="pos"
              v-bind:initialRate="initialRates[key]"
              v-bind:minRate="item.minRate"
              v-bind:maxRate="item.maxRate"
              v-bind:playingColor="item.color"
              v-bind:isActive="sequence.includes(key)"
              v-bind:onRateChange="onRateChange"
              v-on:activate="handleActivate"
              v-on:deactivate="handleDeactivate"
              v-on:adjustRate="handleAdjustRate"
              v-bind:key="key + pos"
              v-bind:activeSound="item.sound" />
      </row>
    </div>
  </reader>
</div>
</template>

<script>
import Row from './Row.vue'
import Cell from './Cell.vue'
import Reader from './Reader.vue'
import TriggerButton from './TriggerButton.vue'
import VariationSelector from './VariationSelector.vue'
import DurationInput from './DurationInput.vue'

export default {
  name: 'Sequencer',
  components: {
    Row,
    Cell,
    Reader,
    TriggerButton,
    VariationSelector,
    DurationInput
  },
  props: {
    instrument: {
      type: Object,
      required: true
    },
    cellDuration: {
      type: Number,
      default: 0.5
    },
    sequenceSize: {
      type: Number,
      default: 9
    },
    backgroundColor: {
      type: String,
      default: '#CCCCCC'
    },
    onRateChange: {
      type: Function,
      default: undefined
    },
    enableTest: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isPlaying: false,
      currentSequencePos: 0,
      sequenceGrid: Array.from(Array(this.sequenceSize), () => []),
      sequenceRates: Array.from(Array(this.sequenceSize), () => ({})),
      selectedVariation: this.instrument.defaultVariation,
      sliceDuration: this.cellDuration
    }
  },
  computed: {
    sequenceDuration: function () {
      return this.sliceDuration * this.sequenceSize
    },
    nextSequencePos: function () {
      return (this.currentSequencePos + 1) % this.sequenceSize
    },
    prevSequencePos: function () {
      return (this.currentSequencePos - 1) % this.sequenceSize
    },
    selectedSounds: function () {
      let sounds = {}

      if (!this.instrument.hasVariations()) {
        sounds = this.instrument.sounds
      } else {
        sounds = this.instrument.getSoundVariations(this.selectedVariation)
      }

      return sounds
    },
    soundIndexes: function () {
      const indexes = {}

      Object.keys(this.selectedSounds).forEach((soundId, index) => {
        indexes[soundId] = index
      })

      return indexes
    },
    sounds: function () {
      let sounds = {}

      if (!this.instrument.hasVariations()) {
        sounds = this.instrument.sounds
      } else {
        for (const varId of Object.keys(this.instrument.variations)) {
          sounds = Object.assign(sounds, this.instrument.getSoundVariations(varId))
        }
      }

      return sounds
    },
    initialRates: function () {
      const rates = {}

      for (const [soundId, sound] of Object.entries(this.selectedSounds)) {
        rates[soundId] = sound.initialRate
      }

      return rates
    }
  },
  methods: {
    stopSequentialSounds: function (prevSequencePos, nextSequencePos) {
      for (const id of this.sequenceGrid[prevSequencePos]) {
        if (!this.isContinuousSound(id, prevSequencePos, nextSequencePos)) {
          this.sounds[id].stop()
          this.$emit('stop', id)
        }
      }
    },
    stopAllSounds: function () {
      for (const track of Object.values(this.sounds)) {
        track.stop()
      }
    },
    playSequentialSounds: function (prevSequencePos, nextSequencePos) {
      for (const id of this.sequenceGrid[nextSequencePos]) {
        if (!this.isContinuousSound(id, prevSequencePos, nextSequencePos)) {
          this.sounds[id].play(this.sequenceRates[nextSequencePos][id])
          this.$emit('play', id)
        }
      }
    },
    isContinuousSound: function (soundId, prevSequencePos, nextSequencePos) {
      return this.sounds[soundId].isPlaying &&
        this.sequenceGrid[prevSequencePos]?.includes(soundId) &&
        this.sequenceGrid[nextSequencePos]?.includes(soundId) &&
        this.sequenceRates[nextSequencePos][soundId] === this.sequenceRates[prevSequencePos][soundId]
    },
    handleInterval: function () {
      const prevSequencePos = this.currentSequencePos
      const nextSequencePos = this.nextSequencePos

      this.stopSequentialSounds(prevSequencePos, nextSequencePos)
      this.playSequentialSounds(prevSequencePos, nextSequencePos)

      this.currentSequencePos = nextSequencePos
    },
    handlePlay: function () {
      this.isPlaying = true

      this.playSequentialSounds(this.prevSequencePos, this.currentSequencePos)
      this.intervalId = setInterval(this.handleInterval.bind(this), this.sliceDuration * 1000)
    },
    handleStop: function () {
      this.isPlaying = false
      this.currentSequencePos = 0

      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      this.stopAllSounds()
    },
    handleActivate: function (soundId, sequencePos) {
      this.sequenceGrid[sequencePos].push(soundId)
      this.sequenceRates[sequencePos][soundId] = this.initialRates[soundId]

      this.$emit('play', soundId)
      this.handleTest(soundId, sequencePos)
    },
    handleDeactivate: function (soundId, sequencePos) {
      const index = this.sequenceGrid[sequencePos].indexOf(soundId)

      this.sequenceGrid[sequencePos].splice(index, 1)
      this.sounds[soundId].stop()

      delete this.sequenceRates[sequencePos][soundId]
      this.$emit('stop', soundId)
    },
    handleAdjustRate: function (soundId, sequencePos, newRate) {
      if (this.sequenceRates[sequencePos][soundId] !== newRate) {
        this.sequenceRates[sequencePos][soundId] = newRate
        this.handleTest(soundId, sequencePos)
      }
    },
    handleTest: function (soundId, sequencePos) {
      if (this.enableTest) {
        const soundRate = this.sequenceRates[sequencePos][soundId]
        this.sounds[soundId].test(soundRate)
      }
    },
    handleSelectVariation: function (variationId) {
      this.selectedVariation = variationId
    },
    handleDurationChange: function (duration) {
      this.sliceDuration = duration
    }
  }
}
</script>

<style scoped>
.MainControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}
/* DESKTOP SIZE */
@media only screen and (min-width: 1800px){
.Sequencer {
    padding: 4px;
    overflow-x: hidden;
    width: 1480px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    color: #FDFDFD;
}

.Sequencer, .Grid {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}

.Controller, .Variations {
    display: flex;
    flex-direction: row;
}

.Controller {
    justify-content: space-between;
}

.PlayingSequencer .Grid {
    pointer-events: none;
    cursor: not-allowed;
}
}
/* LAPTOP SIZE */
@media only screen and (min-width: 1080px) and (max-width: 1800px) {
.Sequencer {
    padding: 4px;
    overflow-x: hidden;
    width: 1000px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    color: #FDFDFD;
}

.Sequencer, .Grid {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.Controller, .Variations {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Controller {
    justify-content: space-between;
}

.PlayingSequencer .Grid {
    pointer-events: none;
    cursor: not-allowed;
}
}
/* MOBILE */
@media only screen and (min-width: 300px) and (max-width: 1080px) {
.Sequencer {
    padding: 4px;
    overflow-x: hidden;
    width: 400px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    color: #D8D8D8;
}

.Sequencer, .Grid {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.Controller, .Variations {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Controller {
    justify-content: space-between;
}

.PlayingSequencer .Grid {
    pointer-events: none;
    cursor: not-allowed;
}
}
</style>
