<template>
<div class="Reader">
    <div class="Area"
         v-if="isActive"
         v-bind:style="{ animationDuration: `${duration}s` }">
      <div class="Tracker"
           v-bind:style="{ borderColor: isActive ? activeColor : inactiveColor }"/>
    </div>
  <slot />
</div>
</template>

<script>
export default {
  name: 'Reader',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 5
    },
    activeColor: {
      type: String,
      default: '#666666'
    },
    inactiveColor: {
      type: String,
      default: '#666666'
    },
    areaWidth: {
      type: Number,
      default: 200
    }
  },
  data: function () {
    return {
      isMoving: false
    }
  }

}
</script>

<style scoped>
.Area {
  top: 0px;
  position: absolute;
  z-index: 2;

  height: 100%;
  width: calc(100% - 100px);

  animation-name: move;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  pointer-events: none;
}

.Reader {
  position: relative;
}

.Tracker {
  left: 0px;
  margin-left: 100px;

  position: absolute;
  border: 3px solid #FFFFFF;
  z-index: 2;
  height: calc(100% - 6px);
}

@keyframes move {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
