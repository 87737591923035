<template>
<div v-bind:class="['Cell', { StaticCell: isStatic }]"
     tabindex="0"
     v-on:mouseover="handleOver"
     v-on:mouseleave="handleLeave"
     v-on:click="handleClick"
     v-on:contextmenu.prevent.stop="handleRightClick"
     v-on:keyup.stop.prevent="handleKeyup"
     v-on:wheel.prevent.stop="handleWheel"
     v-bind:style="{ backgroundColor: currentBackground }">
  {{ text }}
  <div class="Note"
       v-if="isActive"
       v-bind:style="{ backgroundColor: playingColor }" />

  <div class="Progress"
       v-if="isSliderEnable"
       v-bind:style="{ height: progressPosition }" />

  <input type="range"
         class="Slider"
         ref="slider"
         min="0"
         max="100"
         v-on:input="handleSliderMove"
         v-on:change="handleSliderMove"
         v-bind:step="-rateStep"
         v-bind:style="{ display: sliderDisplayStyle }" />
</div>
</template>

<script>
export default {
  name: 'Cell',
  props: {
    initialRate: {
      type: Number,
      default: undefined
    },
    maxRate: {
      type: Number,
      default: undefined
    },
    minRate: {
      type: Number,
      default: undefined
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: undefined
    },
    playingColor: {
      type: String,
      default: 'rgba(196,196,196,0.5)'
    },
    activeColor: {
      type: String,
      default: 'rgba(196,196,196,0.5)'
    },
    staleColor: {
      type: String,
      default: '#EDEDED'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    soundId: {
      type: String,
      default: ''
    },
    sequencePos: {
      type: Number,
      default: -1
    },
    rateStep: {
      type: Number,
      default: 0.1
    },
    onRateChange: {
      type: Function,
      default: function (newValue) {
        const maxValue = Math.log(this.minRate)
        const minValue = Math.log(this.maxRate)
        const scale = (maxValue - minValue) / 100
        return Math.exp(minValue + scale * newValue)
      }
    }
  },
  data: function () {
    return {
      currentRate: this.initialRate,
      currentValue: this.initialValue,
      isSliderEnabled: false,
      sliderValue: 50,
      isHover: false
    }
  },
  computed: {
    initialValue: function () {
      return 100 - this.normalizeRate(this.initialRate)
    },
    progressPosition: function () {
      return `${this.currentValue}%`
    },
    sliderPosition: {
      get: function () {
        return this.$refs.slider.value
      },
      set: function (position) {
        if (position > 0 && position < 100) {
          this.$refs.slider.value = position
          this.$refs.slider.dispatchEvent(new Event('input'))
        }
      }
    },
    isRateEnable: function () {
      return !!this.initialRate && !!this.minRate && !!this.maxRate
    },
    isSliderEnable: function () {
      return !this.isStatic && this.isActive && this.isRateEnable
    },
    currentBackground: function () {
      let currentBackground = this.staleColor

      if (this.isPlaying) {
        currentBackground = this.activeColor
      } else if (this.isHover || this.isActive) {
        currentBackground = this.activeColor
      }

      return currentBackground
    },
    rateDisplayStyle: function () {
      return this.isActive ? 'block' : 'none'
    },
    sliderDisplayStyle: function () {
      return this.isActive && this.isRateEnable ? 'block' : 'none'
    }
  },
  methods: {
    handleOver: function () {
      if (this.isStatic) return false
      this.isHover = true
      if (this.isActive) this.$refs.slider.focus()
    },
    handleLeave: function () {
      if (this.isStatic) return false
      this.isHover = false
    },
    handleWheel: function ({ deltaY }) {
      if (this.isSliderEnable) {
        let newPosition = Number.parseFloat(this.$refs.slider.value)

        if (deltaY > 0) {
          newPosition += this.rateStep * 10
        } else {
          newPosition -= this.rateStep * 10
        }

        this.sliderPosition = newPosition
      }
    },
    handleKeyup: function ({ key }) {
      if (this.isSliderEnable && ['ArrowDown', 'ArrowUp'].includes(key)) {
        let newPosition = Number.parseFloat(this.$refs.slider.value)

        if (key === 'ArrowDown') {
          newPosition += this.rateStep * 10
        } else if (key === 'ArrowUp') {
          newPosition -= this.rateStep * 10
        }

        this.sliderPosition = newPosition
      }
    },
    adjustRate: function (newRate) {
      this.currentRate = newRate
      this.currentValue = this.$refs.slider.value

      this.$emit('adjustRate', this.soundId, this.sequencePos, this.currentRate)
    },
    handleClick: function () {
      if (this.isStatic) return false

      if (!this.isActive) {
        this.$emit('activate', this.soundId, this.sequencePos)
        this.initializeSlider()
      }
    },
    isRateValid: function (newRate) {
      return newRate && newRate < this.maxRate && newRate > this.minRate
    },
    normalizeRate: function (rate) {
      return ((rate - this.minRate) / (this.maxRate - this.minRate)) * 100
    },
    denormalizeRate: function (value) {
      return (value / 100) * (this.maxRate - this.minRate) + this.minRate
    },
    initializeSlider: function () {
      this.sliderPosition = this.initialValue
      this.$refs.slider.focus()
    },
    handleRightClick: function () {
      if (this.isStatic) return false

      if (this.isActive) {
        this.$emit('deactivate', this.soundId, this.sequencePos)
      }
    },
    handleSliderMove: function (e) {
      if (this.isStatic || !this.isRateEnable) return false

      const sliderValue = parseFloat(e.target.value)
      const newRate = this.onRateChange(sliderValue)

      if (this.isRateValid(newRate)) {
        this.adjustRate(newRate)
      }
    }
  },
  watch: {
    currentRate: function (rate) {
      console.debug(`new rate: ${rate}`)
    },
    isSliderEnable: function () {
      console.debug(`min rate: ${this.minRate}`)
      console.debug(`max rate: ${this.maxRate}`)

      console.debug(`initial rate: ${this.initialRate}`)
      console.debug(`initial value: ${this.initialValue}`)
    }
  }
}
</script>

<style scoped>
/* DESKTOP SIZE */
@media only screen and (min-width: 1800px){
.Cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    font-size: 15px;
    user-select: none;
}

.Cell:not(.StaticCell) {
    cursor: pointer;
}

.Cell:active,.Cell:hover  {
    background-color: rgba(196,196,196,0.5);
}

.Cell:visited {
    background-color: rgba(196, 196, 196, 0.4);
}

.Note {
    width: 100%;
    height: 100%;
}

.Slider {
  cursor: grap;

  transform: rotate(90deg);

  position: absolute;

  width: 80px;
  height: 100px;

  padding: 0;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]:focus {
  outline: none;
}

.Slider::-moz-range-thumb {
  -webkit-appearance: none;
  height: 100%;

  filter: saturate(6);
  border: none;
  border-radius: 0px;
}

.Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #F3F3F3;

  height: 100px;
  width: 15px;

  border-radius: 0px;
}

.Slider::-moz-range-track {
  height: 100%;
  width: 100%;

  background: transparent;
  border: none;
  border-radius: 0px;

  border-color: transparent;
  color: transparent;
}

.Progress {
  position: absolute;
  top: 0px;
  width: 100%;

  background: rgba(196,196,196,0.5);
  height: 100%;
  border: none;
  border-radius: 0px;
}
}
/* LAPTOP SIZE */
@media only screen and (min-width: 1080px) and (max-width: 1800px) {
.Cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    font-size: 15px;
    user-select: none;
}

.Cell:not(.StaticCell) {
    cursor: pointer;
}

.Cell:active,.Cell:hover  {
    background-color: rgba(196,196,196,0.5);
}

.Cell:visited {
    background-color: rgba(196, 196, 196, 0.4);
}

.Note {
    width: 100%;
    height: 100%;
}

.Slider {
  cursor: grap;

  transform: rotate(90deg);

  position: absolute;

  width: 80px;
  height: 100px;

  padding: 0;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]:focus {
  outline: none;
}

.Slider::-moz-range-thumb {
  -webkit-appearance: none;
  height: 100%;

  filter: saturate(6);
  border: none;
  border-radius: 0px;
}

.Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #F3F3F3;

  height: 100px;
  width: 15px;

  border-radius: 0px;
}

.Slider::-moz-range-track {
  height: 100%;
  width: 100%;

  background: transparent;
  border: none;
  border-radius: 0px;

  border-color: transparent;
  color: transparent;
}

.Progress {
  position: absolute;
  top: 0px;
  width: 100%;

  background: rgba(196,196,196,0.5);
  height: 100%;
  border: none;
  border-radius: 0px;
}
}
</style>
